/* -----------------------------------------------
				            CSS Styles
--------------------------------------------------

Table of Content

	1. Body and Core Css
     - 1.1 Typography
     - 1.2 Button Style
     - 1.4 Form Style
     - 1.5 NavBar

	2. Home Page
     - 2.1 Banner Section

----------------------------------- */

/* -----------------------------------
		    1. Body and Core Css
----------------------------------- */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;

  &:after,
  &:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font: {
    size: 14px;
    weight: 500;
    family: "Nunito", sans-serif;
  }
  line-height: 1.3;
  text-align: center;
  color: #444444;
  background: #fff;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 1.1 Typography */
a {
  color: #d94c48;
  outline: none;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    color: #000;
    outline: none;
    text-decoration: none;
  }
}

p {
  color: #444444;
  font: {
    size: 14px;
    weight: 600;
  }
  line-height: 1.7;
}

img {
  max-width: 100%;
}

dl,
ol,
ul {
  padding: 0;
}

iframe {
  display: none;
}

.title {
  span {
    padding: 7px 15px;
    color: #fff;
    font: {
      size: 12px;
      weight: 800;
    }
    text-transform: uppercase;
    border-radius: 50px;
    background-color: #fb725a;
    box-shadow: 0 9px 15px rgba(51, 83, 145, 0.1);
  }

  h1 {
    padding: 30px 0;
    font-weight: 600;

    b {
      font-weight: 600;
      background: linear-gradient(to right, #ff5c5c 0%, #435cc4 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

/* 1.2 Button Style */
.btn-st {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  text-transform: uppercase;
  border: 1px solid transparent;
  background-color: #04091e;
  box-shadow: 0 9px 15px rgba(51, 83, 145, 0.1);
  transition: all 0.3s;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  &.transparent {
    border: 1px solid #d94c48;
    background-color: transparent;

    &:hover {
      color: #d94c48;
    }
  }

  &:hover {
    color: #d94c48;
    border: 1px solid #d94c48;
    background-color: transparent;
  }
}

.btn-groups {
  display: flex;
  align-items: center;
}

/* 1.4 Form Style */
.input-group {
  text-align: left;
  margin-bottom: 30px;
  border-radius: 10px;

  input,
  select {
    color: #444;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    background-color: #f4f8f7;
  }

  .form-control[readonly] {
    background-color: #f4f8f7;
  }

  .input-group-text {
    position: relative;
    border: none;
    border-radius: 10px;
    background-color: #f4f8f7;

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      right: 1px;
      background-color: #fff;
    }
  }
}

/* 1.5 NavBar */
.navbar-custom {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  border-bottom: 1px solid #dadada;
  transition: all 0.3s;
  z-index: 9999;

  .navbar-brand {
    img {
      max-width: 250px;
    }
  }

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    padding: 0;

    a {
      &.link {
        position: relative;
        color: #000;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 15px;
        transition: all 0.3s;

        &.active {
          color: #d94c48;
        }

        &:hover {
          color: #d94c48;
        }
      }
    }

    .btn-st {
      position: relative;
      margin-left: 20px;

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 70%;
        top: 50%;
        left: -20px;
        background-color: #d4d4d4;
        transform: translateY(-50%);
      }
    }
  }

  .dropdown {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 70%;
      top: 50%;
      left: 8px;
      background-color: #d4d4d4;
      transform: translateY(-50%);
    }

    .dropdown-toggle {
      &.nav-link {
        color: #000;
        font: {
          size: 14px;
          weight: 600;
        }
        padding: 10px 15px 10px 50px;
        border-radius: 5px;
        background-color: transparent;
        border: none;
        transition: all 0.3s;

        &:hover,
        &:focus {
          color: #d94c48;
          background-color: transparent;
        }
      }

      &::after {
        opacity: 1;
        vertical-align: 0.1em;
        border-top: 0.35em solid;
        transition: transform 0.3s;
      }

      &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        top: 50%;
        left: 25px;
        background-size: cover;
        background-image: url("../../img/icon/earth.svg");
        transform: translateY(-50%);
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      font: {
        size: 14px;
        weight: 600;
      }
      color: #fff;
      padding: 5px;

      img {
        max-width: 15px;
        margin-right: 7px;
        border-radius: 5px;
      }

      &:active,
      &:hover,
      &:focus {
        color: #fff;
        border-radius: 3px;
        background-color: #3b3b3b;
      }
    }

    .dropdown-menu {
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.7);
      border: 1px solid rgba(77, 77, 77, 0.57);
    }

    .dropdown.show {
      .dropdown-toggle {
        &::after {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}

/* 1.6 Wave Style */
.wave-firts {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  z-index: 1;
}

.wave-second {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  filter: blur(10px);
  z-index: 0;
  opacity: 0.03;
}

/* 1.7 Modal */
.modal {
  .modal-header {
    position: absolute;
    right: 0;
    border: none;
    z-index: 9;
  }

  p {
    margin-top: 10px;
    font-size: 16px;
  }

  .send-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    img {
      max-width: 200px;
    }
  }

  .btn-st {
    text-transform: none;
  }
}

.valid {
  position: absolute;
  width: 100%;
  bottom: 66px;
  left: 50%;
  padding: 0 20px;
  transform: translateX(-50%);

  .invalid-feedback {
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
    text-align: left;
    z-index: 2;

    svg {
      margin-right: 7px;
    }

    &.active {
      display: flex;
    }

    &.success,
    &.success-req {
      color: #47d071;
    }
  }
}

/* ------------------------------
		    2. Home Page
------------------------------ */
/* 2.1 Banner Section */
.banner {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  padding-top: 150px;
  background-size: cover;
  background-image: url(../../img/backgrounds/banner_bg.png);
  background-position: center center;

  .container {
    position: relative;
    z-index: 1;

    h1 {
      font: {
        size: 55px;
        weight: 700;
      }
      text-align: center;
    }

    p {
      font: {
        size: 16px;
        weight: 600;
      }
      color: #959595;
      margin: 40px 0;
      text-align: center;
    }

    .btn-groups {
      justify-content: center;

      .btn-st {
        font-size: 20px;
        padding: 15px 20px;
        text-transform: none;
      }
    }
  }

  span {
    position: absolute;
    opacity: 0.4;
    animation: speed 2s alternate infinite ease-in;

    &:nth-child(1) {
      top: 21%;
      left: 9%;
      animation: translateY-5 2s alternate infinite ease-in;
    }

    &:nth-child(2) {
      top: 40%;
      left: 18%;
      animation: translateY-7 2s alternate infinite ease-in;
    }

    &:nth-child(3) {
      top: 70%;
      left: 8%;
      animation: translateY-20 3s alternate infinite ease-in;
    }

    &:nth-child(4) {
      top: 18%;
      right: 21%;
      animation: translateY-5 2s alternate infinite ease-in;
    }

    &:nth-child(5) {
      bottom: 11%;
      right: 4%;
      animation: translateY-7 2s alternate infinite ease-in;
    }

    &:nth-child(6) {
      top: 40%;
      right: 7%;
      animation: translateY-20 2s alternate infinite ease-in;
    }
  }

  .shape {
    position: absolute;
    left: 50%;
    bottom: 0;
    max-width: 75%;
    transform: translateX(-50%);
  }
}

/* 2.2 Exchange Section */
.exchange {
  position: relative;
  padding-bottom: 100px;

  .box {
    position: relative;
    margin: 100px 0 70px;
    padding-bottom: 30px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px dashed #d5d5d5;
    box-shadow: 0 9px 30px rgba(51, 83, 145, 0.1);

    .leaf {
      position: absolute;
      left: -80px;
      bottom: 130px;
      max-width: 100px;
      opacity: 0.7;
      transform: scale(-1, 1);
      z-index: -1;
    }

    h1 {
      position: relative;
      padding: 30px;
      font: {
        weight: 600;
      }
      text-transform: capitalize;
      margin-bottom: 30px;

      span {
        background: linear-gradient(to right, #ff5c5c 0%, #435cc4 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0px;
        bottom: 0;
        background-color: #f2f2f2;
      }

      &::before {
        content: "";
        position: absolute;
        width: 70px;
        height: 86px;
        right: 15px;
        bottom: 10px;
        background-image: url(../../img/coins.png);
        background-repeat: no-repeat;
      }
    }

    .form {
      padding: 20px 30px;

      h4 {
        position: relative;
        text-align: left;
        padding-left: 45px;
        margin-bottom: 20px;

        &::after,
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          top: 50%;
          left: 19px;
          border-radius: 50%;
          background-color: #fb725a;
          transform: translateY(-50%);
        }

        &::before {
          width: 12px;
          height: 12px;
          left: 15px;
          background: #e9ecef;
        }
      }

      .select {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-left: 3px solid #fff;
        border-radius: 0 10px 10px 0;
        background: #e9e9e6;

        p {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 185px;
          font-size: 15px;
          padding: 10px 30px 10px 20px;
          margin-bottom: 0;

          span {
            width: 15px;
            margin-right: 10px;

            img {
              max-height: 15px;
            }
          }

          &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 17px;
            right: 10px;
            background-image: url(../../img/icon/angle-down.svg);
            transition: all 0.3s;
          }

          &.active {
            &::after {
              transform: rotate(180deg);
            }
          }
        }

        ul {
          display: none;
          position: absolute;
          width: 100%;
          top: 60px;
          left: 0;
          padding: 15px;
          border-radius: 10px;
          background: #fff;
          box-shadow: 0 9px 30px rgba(51, 83, 145, 0.1);
          z-index: 9;

          li {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            padding: 10px 5px;
            border-bottom: 1px solid #f3f3f3;
            transition: all 0.3s;

            span {
              width: 15px;
              margin-right: 10px;

              img {
                max-height: 15px;
              }
            }

            &:hover {
              border-radius: 5px;
              background: #f3f3f3;
            }
          }

          &.active {
            display: block;
          }
        }
      }
    }

    p {
      margin-bottom: 40px;
    }
  }

  .element {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 160px;
    height: 180px;
    margin: 0 auto 30px;
    padding: 0 15px;
    background-image: url(../../img/icon/octagon.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;

    svg {
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      margin: 0;
    }
  }
}

/* 2.3 Advantage Section */
.advantage {
  position: relative;
  padding: 30px 0 100px;

  .item {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 1px dashed #e9eaec;

    .header {
      position: relative;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      margin-bottom: 20px;

      svg {
        margin-right: 10px;
      }

      h4 {
        text-align: left;
        margin-bottom: 0;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        border-bottom: 1px dashed #e9eaec;
      }
    }

    p {
      color: #777;
      font-size: 16px;
      text-align: left;
      margin-bottom: 0;
    }
  }
}

/* 2.4 About Section */
.about {
  position: relative;
  padding: 50px 0 150px;

  .title {
    text-align: left;
  }

  p {
    text-align: left;
  }

  .btn-groups {
    margin-top: 40px;

    .btn-st {
      font-size: 16px;
      text-transform: none;
    }
  }

  .wave-firts,
  .wave-second {
    transform: rotate(-180deg);
  }

  .wave-firts {
    bottom: 15px;
  }
}

/* ------------------------------
		       3. Promo
------------------------------ */
.promo {
  position: relative;
  padding: 150px 0;

  .content {
    display: flex;
    align-items: center;
    background-color: #f1423e;
    border-radius: 10px;

    .info {
      .title {
        span {
          background-color: #000;
        }

        h1 {
          color: #fff;
        }
      }

      .input-group {
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.4);

        input {
          font-size: 22px;
        }

        .input-group-text {
          &::after {
            background-color: #f1423e;
          }
        }

        .copy {
          display: flex;
          align-items: center;
          padding: 0 5px;
          border-radius: 0 10px 10px 0;
          background-color: #f4f8f7;
        }
      }

      p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #fff;
        font-size: 16px;

        svg {
          margin-right: 7px;
        }
      }
    }
  }

  .wave-firts,
  .wave-second {
    transform: rotate(-180deg);
  }

  .wave-firts {
    bottom: 15px;
  }
}

/* ------------------------------
		       4. FAQ
------------------------------ */
.faq {
  position: relative;
  padding: 150px 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    h1 {
      font-weight: 600;
    }

    img {
      max-width: 200px;
    }
  }

  .accordion {
    border-radius: 5px;
    border: 1px dashed #d5d5d5;
    box-shadow: 0 9px 30px rgba(51, 83, 145, 0.1);
  }

  .accordion-button {
    font-weight: 600;

    svg {
      margin-right: 7px;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &:not(.collapsed) {
      color: #dc5b57;
      background-color: #efefef;
    }
  }

  .accordion-item {
    border: none;
    border-bottom: 1px dashed #d5d5d5;

    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .accordion-body {
    font-size: 16px;
    text-align: left;
  }

  .wave-firts,
  .wave-second {
    transform: rotate(-180deg);
  }

  .wave-firts {
    bottom: 15px;
  }
}

/* ------------------------------
		    5. Policy and Terms
------------------------------ */
.policy {
  position: relative;
  padding: 150px 0;

  .title {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;

    h1 {
      padding: 0;
      margin: 0;
    }

    svg {
      margin-bottom: 10px;
    }
  }

  .item {
    margin-bottom: 30px;

    h4 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      span {
        font: {
          size: 16px;
          weight: 600;
        }
        padding: 3px 5px;
        margin-right: 7px;
        border-radius: 5px;
        background-color: #fed5ce;
      }
    }

    p {
      text-align: left;
      padding-left: 30px;
    }
  }

  .wave-firts,
  .wave-second {
    transform: rotate(-180deg);
  }

  .wave-firts {
    bottom: 15px;
  }
}

/* ------------------------------
		        7. Footer
------------------------------ */
footer {
  padding: 0 0 50px;

  h5 {
    position: relative;
    text-align: right;
    margin-bottom: 25px;
  }

  .left-side {
    display: flex;
    flex-direction: column;

    p {
      color: #777;
      text-align: left;
    }

    .btn-st {
      padding: 7px 10px;
    }
  }

  .center-side {
    padding-top: 30px;
  }

  .right-side {
    ul {
      li {
        position: relative;
        padding-right: 15px;
        text-align: right;
        margin-bottom: 10px;
        transition: all 0.3s;

        a {
          color: #444444;
          font: {
            size: 15px;
            weight: 600;
          }
          transition: all 0.3s;

          &:hover {
            color: #d94c48;
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          top: 7px;
          right: 0;
          border-radius: 50%;
          background: linear-gradient(135deg, #f75c60, #ffb4af 70.71%);
        }

        &:hover {
          padding-right: 20px;
          transition: all 0.3s;
        }
      }
    }
  }
}


.wallet-container{
  width: 100%;
  height: 100%;
  margin-top: 150px;
  h3{
    margin: 15px 0 15px 0;
  }
  h3.wallet{
    color: #d94c48;
  }
  h3.amount{
    color: #ab3108;
  }
  .qr-photo{
    margin: auto;
    width: 300px;
    height: 300px;
  }
}
.timer{font-size: 24px; color: #d94c48;}