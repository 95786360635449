@keyframes translateY-5 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes translateY-7 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(7px);
  }
}

@keyframes translateY-20 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
