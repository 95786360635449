/* xl, large: 1200px */
$breakpoint-xl: 1200px;
/* lg, large: 991px */
$breakpoint-lg: 991px;
/* md, large: 768px */
$breakpoint-md: 768px;
/* sm, large: 575px */
$breakpoint-sm: 575px;

@media screen and (max-width: $breakpoint-xl) {
  .exchange {
    .box {
      .form {
        padding: 20px 15px;

        .select {
          p {
            white-space: pre;
            width: 160px;
            font-size: 14px;
            padding: 10px 30px 10px 10px;
          }
        }
      }
    }
  }

  .promo {
    .content {
      img {
        max-width: 45%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .navbar-light {
    .navbar-toggler {
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
      background: #04091e;
      box-shadow: 0 9px 15px rgba(51, 83, 145, 0.1);
    }

    .navbar-toggler-icon {
      background-image: url(../../img/icon/menu.svg);
    }
  }

  .navbar-custom {
    .navbar-nav {
      margin: 0;
      flex-direction: row;
    }

    .dropdown-menu {
      position: absolute;
      left: 10px;
    }

    .navbar-collapse {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100px;
      background: rgba(0, 0, 0, 0.85);

      .navbar-nav {
        flex-direction: column;

        a {
          color: #fff;
        }
      }
    }
  }

  .banner {
    .container {
      h1 {
        font-size: 46px;
      }
    }

    span {
      &:nth-child(1) {
        top: 20%;
      }
      &:nth-child(2) {
        top: 50%;
        left: 8%;
      }
      &:nth-child(3) {
        top: 85%;
        left: 20%;
      }
      &:nth-child(4) {
        top: 20%;
      }
      &:nth-child(5) {
        bottom: 15%;
        right: 10%;
      }
    }
  }

  .exchange {
    .mt-20 {
      margin-top: 0 !important;
    }
  }

  .about {
    .title {
      margin-top: 30px;
      text-align: center;
    }
  }

  .promo {
    .content {
      .info {
        .title {
          h1 {
            font-size: 26px;
            padding: 15px 0;
          }
        }
      }
    }
  }

  footer {
    .center-side {
      padding-top: 0;
    }

    .right-side {
      margin-top: 30px;
      h5 {
        text-align: center;
      }

      ul {
        li {
          text-align: center;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .wallet-container{
    h3{
      font-size: 14px;
    }
    h5{
      font-size: 12px;
    }
    h6{
      font-size: 11px;
    }
    h8{
      font-size: 9px;
    }
  }
  .navbar-custom {
    .navbar-brand {
      img {
        max-width: 150px;
      }
    }

    .navbar-nav {
      .btn-st {
        display: none;
      }
    }
  }

  .banner {
    .container {
      h1 {
        font-size: 26px;
      }
    }

    .shape {
      bottom: 50%;
    }

    span {
      &:nth-child(1) {
        top: 20%;
      }
      &:nth-child(2),
      &:nth-child(6) {
        display: none;
      }
      &:nth-child(3) {
        top: 85%;
        left: 10%;
      }
      &:nth-child(4) {
        top: 28%;
        right: 10%;
      }
      &:nth-child(5) {
        bottom: 12%;
        right: 7%;
      }
    }
  }

  .exchange {
    padding-bottom: 50px;

    .box {
      h1 {
        &::before {
          width: 55px;
          height: 50px;
        }
      }

      .leaf {
        display: none;
      }
    }
  }

  .advantage {
    padding: 50px 0;

    .mt-50 {
      margin-top: 0 !important;
    }
  }

  .promo {
    .content {
      padding: 30px;

      img {
        display: none;
      }
    }
  }

  .faq {
    .header {
      img {
        display: none;
      }
    }
  }

  footer {
    .left-side {
      margin-bottom: 50px;
      align-items: center;

      p {
        text-align: center;
      }
    }

    .center-side {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
}
